var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answer_record"},[_c('StudentHeader',{attrs:{"videoFlag":true}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"answer_container"},[_c('div',{staticClass:"total_group"},[_c('div',{staticClass:"title"},[_vm._v(" 总得分："),_c('span',[_vm._v(_vm._s(_vm.items.stuCore)+"分")]),_vm._v(" 试卷名称："),_c('span',[_vm._v(_vm._s(_vm.items.examName))]),_vm._v(" 答题要求："),_c('span',{domProps:{"innerHTML":_vm._s(_vm.items.answerDesc)}})])]),_c('div',{staticClass:"answer_content"},[(_vm.eduTestPaperQuestions.length)?_c('el-collapse',{model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.eduTestPaperQuestions),function(edu,index){return _c('el-collapse-item',{key:index,attrs:{"name":edu.id}},[_c('template',{slot:"title"},[_c('span',{staticClass:"collapse_header",domProps:{"innerHTML":_vm._s(edu.questionName)}})]),_c('div',{staticClass:"collapse_content"},[_c('div',{staticClass:"answer_desc"},[_vm._v(" 答题要求："),_c('span',{domProps:{"innerHTML":_vm._s(edu.answerDesc)}})]),_vm._l((edu.eduCourseQuestionVos),function(c,i){return _c('div',{key:i,staticClass:"answer_items"},[_c('div',{staticClass:"items_title_group"},[_c('div',[_c('p',{staticStyle:{"width":"28px","height":"28px"}},[(c.subjectiveFlag === 1)?_c('svg-icon',{attrs:{"name":c.correctFlag === 1
                          ? 'success'
                          : c.correctFlag === 0
                          ? 'error'
                          : ''}}):_vm._e()],1),_c('p',[_vm._v(_vm._s(i + 1)+". ("+_vm._s(c.itemScore)+"分)  ")]),_c('p',{domProps:{"innerHTML":_vm._s(c.questionTitle)}})]),(c.enabled === 1)?_c('p',{staticClass:"give_score",class:c.correctFlag === 1 || c.stuItemScore
                      ? 'success'
                      : c.correctFlag === 0 || !c.stuItemScore
                      ? 'error'
                      : ''},[_vm._v(" +"+_vm._s(c.stuItemScore)+"分 ")]):_vm._e()]),_c('el-radio-group',{staticClass:"items_radio"},_vm._l((c.eduCourseQuestionOps),function(r,i){return _c('el-radio',{key:i,attrs:{"label":r.id,"disabled":""}},[_vm._v(" "+_vm._s(r.itemKey)+". "+_vm._s(r.itemVal)+" ")])}),1),_c('div',{staticClass:"items_answer_group"},[_c('div',[_c('p',[_vm._v("我的答案："+_vm._s(c.answerVal))]),_c('p',{staticClass:"questionKey"},[_vm._v(" 参考答案："),_c('span',{staticClass:"success",domProps:{"innerHTML":_vm._s(c.questionKey)}})])]),_c('div',{staticClass:"parse"},[_vm._v(" 答案解析："),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(c.questionAnalyze)}})])])],1)})],2)],2)}),1):_c('div',{staticClass:"noneData"},[_vm._v("暂无答题记录")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }