<style scoped lang="less">
.Student-Header {
	height: @headerHeight;
	background: #fff;
	position: relative;
	display: inline-flex;
	z-index: 2;
	align-items: center;
	width: 100%;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	.logo {
		width: calc(@menuWidth - 1px);
		height: 100%;
		font-size: 22px;
		position: relative;
		display: inline-flex;
		align-items: center;
		color: #fff;
		font-weight: bold;
		text-align: center;
		background: linear-gradient(90deg, #307ef3, #5e9eff);
		img {
			width: 80px;
			height: 66px;
			display: block;
			margin: 0 3px;
		}
		span {
			position: absolute;
			display: inline-block;
			right: 28px;
		}
	}

	.right {
		position: relative;
		width: calc(100% - (@menuWidth + 1px));
		// width: 100%;
		display: inline-flex;
		justify-content: space-between;
		align-items: center;
		.announcement {
			padding-left: 30px;
			display: flex;
			align-items: center;
			@height: 30px;
			.announcement-icon {
				color: @mainColor;
				width: 30px;
				height: @height;
				cursor: pointer;
			}
			.announcement-message {
				position: relative;
				height: @height;
				line-height: @height;
				width: 300px;
				margin-left: 10px;
				overflow: hidden;
				white-space: nowrap;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				> p {
					position: absolute;
					top: 0;
					right: -100px;
					font-size: 18px;
					font-weight: bold;
					height: @height;
					animation: 15s linear 0s infinite normal none running go-left;
					user-select: none;
				}

				@keyframes go-left {
					0%,
					35% {
						right: -120px;
					}
					35%,
					70% {
						right: 150px;
					}
					70%,
					100% {
						right: 300px;
					}
				}
			}
		}

		.userInfo {
			display: flex;
			align-items: center;
			font-size: 18px;
			padding-right: 60px;
			.night_switch_group {
				display: flex;
				align-items: center;
				.night_switch {
					margin-left: 5px;
					margin-right: 15px;
				}
			}
			.username {
				margin: 0 12px;
				font-weight: bold;
			}
			.download {
				cursor: pointer;
				margin: 0 15px;
			}
			.tools {
				cursor: pointer;
			}
			i {
				font-size: 26px;
			}
		}
	}
}
.Student-Header-night {
	background: #222324;
	.logo {
		background: #222324;
	}
	.userInfo {
		p span {
			color: #898aba;
		}
	}
}
</style>
<template>
	<div>
		<div class="Student-Header-mobile">
			<i class="el-icon-arrow-left" style="color: #fff;font-size: 40px;" @click="backpage()"></i>
		</div>
		<div class="Student-Header" :class="nightSwitch ? 'Student-Header-night' : ''">
			<div class="logo">
				<img src="../../assets/stu_tea_logo.png" alt="和学在线" />
				<span>{{ teacher ? '教师' : '学生' }}空间</span>
			</div>
			<div class="right">
				<div class="announcement">
					<div class="announcement-icon" v-if="!videoFlag"><svg-icon name="announcement"></svg-icon></div>
					<div class="announcement-message" v-if="!videoFlag">
						<p>{{ message }}</p>
					</div>
				</div>

				<div class="userInfo">
					<p class="night_switch_group" v-if="night">
						<span>夜间模式</span>
						<el-switch v-model="nightSwitch" @change="switchChange" class="night_switch"></el-switch>
					</p>
					<p>
						<span>您好</span>
						<span class="username">{{ user && user.name }}</span>
						<span>{{ login_date }}</span>
					</p>
					<!-- <p class="download" v-if="!videoFlag">
          <i class="el-icon-download"></i>
        </p> -->
					<p class="tools" v-if="!videoFlag">
						<el-dropdown @command="handleCommand">
							<span class="el-dropdown-link"><i class="el-icon-more el-icon--right"></i></span>
							<el-dropdown-menu slot="dropdown"><el-dropdown-item command="out_login">退出</el-dropdown-item></el-dropdown-menu>
						</el-dropdown>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { dateTimeFormat } from '@/tools/date';
export default {
	name: 'student-header',
	props: {
		teacher: Boolean,
		night: { type: Boolean, default: false },
		videoFlag: { type: Boolean, default: false }
	},
	data() {
		return {
			user_name: null,
			login_date: dateTimeFormat(new Date()),
			timer: null,
			nightSwitch: false
		};
	},
	computed: {
		user() {
			return this.$store.getters.getUser;
		},
		message() {
			return this.$store.getters.getHeaderNotice;
		}
	},
	mounted() {
		this.timer = setInterval(() => {
			this.login_date = dateTimeFormat(new Date());
		}, 1000);
	},
	// 销毁时清除计时器
	beforeDestroy() {
		if (this.timer) {
			clearInterval(this.timer);
		}
	},
	methods: {
		switchChange(value) {
			if (value) {
				this.$emit('isNight', value);
			} else {
				this.$emit('isNight', value);
			}
		},
		handleCommand(comd) {
			if (comd == 'out_login') {
				localStorage.clear();
				sessionStorage.removeItem('teacher_menu_switch');
				this.$router.push('/Online/index');
			}
		},
		backpage(){
			history.go(-1);
		}
	}
};
</script>
